<template>
	<div class="helptableFormHeader puiformheader">
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'helptable-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'helptable'
		};
	},
	computed: {
	}
}
</script>
